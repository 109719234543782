// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:26+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            f19c5cc9e0f7a78d43237a4e5e9354f6
//    Signature:      eyJpdiI6IkFhQ3JVV1REMktOMXJKRUd6eitTbFE9PSIsInZhbHVlIjoiaFhTV1RJRGtVQUg2OHNvOVVhOCtUYml4dVhacDNFY0lVc2FLd2diOFcxa09JaXg3bGJiUVgwWW53d3JSWFhuYUFCNUNkUFVhWUlnK1c2U1VkMFY5dldvT3JNMGI3SDA2TDJEN2ZFSG8zdUExTENGdCtTemVBQmVFeFoyUUlKUFwvK1wvUzFPd1VrdlluQzJBNmZUUHFMV1FtUTRFeEQydUlwY1NRMmx0T3M2QlpUUkFHYThZdVNnZ2xCc3c3cE5MTlFNMVM0cjVCUUhrTEdRZnBiWnlsb0QzZEp0Wnd5S2dpY1dGMGxZTWc0cWFmTmJoSlYzK3NFRFRmcW8wTVU5TzhWNHlzRkV0U3k5QVBHelFiZE5zbjBYQXAyQjZHRTdGSXAwZ2FaUHkyVXZqMDBrQjRISFk2dWY5cFZxNEEwcXVGODFPeW1sRU9tZFA0RUJ5cmcySnZiS1hmdXIzUmR2XC9EbmxvTzQ4XC9FYjUwaHZvc3FzcENuS0UydU9LdklXTFlMK0NDUW9IYW1pZVhWY1oxTGdKTHM1TEE9PSIsIm1hYyI6ImZmMDIyMDJjYzU1MGRmZmZhODAwOGY2MmU3ZWY4NWYxZjMzYjM0OGRlYzFjOTI5NjlkODk4ZTgyOTYwYzIwZGUifQ==
if (!customElements.get('product-modal')) {
  customElements.define('product-modal', class ProductModal extends ModalDialog {
    constructor() {
      super();
    }

    hide() {
      super.hide();
    }

    show(opener) {
      super.show(opener);
      this.showActiveMedia();
    }

    showActiveMedia() {
      this.querySelectorAll(`[data-media-id]:not([data-media-id="${this.openedBy.getAttribute("data-media-id")}"])`).forEach((element) => {
          element.classList.remove('active');
        }
      )
      const activeMedia = this.querySelector(`[data-media-id="${this.openedBy.getAttribute("data-media-id")}"]`);
      const activeMediaTemplate = activeMedia.querySelector('template');
      const activeMediaContent = activeMediaTemplate ? activeMediaTemplate.content : null;
      activeMedia.classList.add('active');
      activeMedia.scrollIntoView();

      const container = this.querySelector('[role="document"]');
      container.scrollLeft = (activeMedia.width - container.clientWidth) / 2;

      if (activeMedia.nodeName == 'DEFERRED-MEDIA' && activeMediaContent && activeMediaContent.querySelector('.js-youtube'))
        activeMedia.loadContent();
    }
  });
}
